import { useTranslation } from 'react-i18next'
import Check from '~/components/icons/Check'
import Typography from '~/components/Typography'
import { parseSafeArrayStrings } from '~/helpers/string'

const ResumeOffer = () => {
  const { t } = useTranslation(['common'])

  const resumeOffer = parseSafeArrayStrings(
    t('common:suscriptionPoints', {
      returnObjects: true
    })
  )

  return (
    <ul className="flex flex-col gap-y-3 sm:items-center lg:items-start">
      {resumeOffer.map((label) => {
        return (
          <li key={label}>
            <Typography
              variant="body2"
              component="h4"
              className="xs:block flex items-start gap-x-3 sm:flex sm:text-left"
            >
              <Check className="xs:mr-3 mt-1 inline-block align-middle text-black/30" />
              {label}
            </Typography>
          </li>
        )
      })}
    </ul>
  )
}

export default ResumeOffer
